.akwaba {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: beige;
  min-height: 350px;
  font-size: x-large;  
  background: url("./clientapp/img/logo2.png");      
}
.container{

}
.adminpageheader {
  width: 100%;
  background-color: #0ea1e6;
  height: 100%;
}
.adminpage {
  width: 100%;   
}

.akwaba-lang {
  padding: 10px 16px;
  background: #555;
  color: #f1f1f1;  
  top: 0;
  width: 100%;
  padding-bottom:10px
}

.akwaba-header {
 bottom:0px;
 margin-bottom: 0px;
 padding-bottom: 0px;
 background-color: #20b29f; 
}

.akwaba-header-ul{
 color: whitesmoke
 }
 

.akwaba-container-items {
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px; 
  width: 100%;
}

.akwaba-container-items-adminpage {
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px; 
  background-color: white;
  width: 100%;
  list-style-type:none;
}
.akwaba-tiles-navlinks-header {
  color: white;
  font-family: verdana;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.akwaba-tiles-navlinks-inbody {
  color: magenta;
  font-family: verdana;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.akwaba-tiles-navlinks {
  color: white;
  background-color: #0ea1e6;
  font-family: verdana;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.akwaba-spacing {
  padding: 10px 10x 10px 10px;
  font-family: verdana;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.akwaba-tiles {
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
  color: navy;
  border-radius:  5px;
  border: 5px;  
}

.akwaba-group-tiles{
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
  color: navy;
  border-radius: 5px;
  border:  5px;
  border-color:  #0ea1e6;
  margin-top: 10px;
  margin-bottom: 10px;
}

.comingsoon{
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
  color: navy;
  border-radius: 5px;
  border:  5px;
  border-color: green; 
  padding-top: 50px;
  padding-bottom: 10px;
  height:100px;
  width: 70%;  
  margin-left: 18%;
  margin-top: 5%;
  margin-bottom: 5%;
}


.footer {
  background-color: #cfb53b;
  border-radius: 3px;
  align-content: center;
  text-align: center;
  height: 100px;
  color: #ffffff;
}

.footer-akwaba {
  flex-direction: column;
  padding: 10px 20px;
  text-align: center;
  color: white;
  width: 100%;
  background-color: #0ea1e6;
  height: 200px;
}

.content-akwaba {
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
  color: goldenrod;
  width: 100%;
  background-color: #f4f7f8;
  min-height: 100%;
  padding: 20px;
  padding-bottom: 0px;
}

.content-justscript-akwaba {
  align-items: center;
  justify-content: center;  
  text-align: left;
  color: navy;
  width: 80%;
  margin-left: 10%;
  background-color: #f4f7f8;
  min-height: 100%;
  padding: 10px 20px;
  padding-bottom: 100px;
}

.header-collapse {
  cursor: pointer;
  border: solid 1px #f2f2f2;
  padding: 15px;
  background-color: #0089cc;
  color: #fff;
  font-family: verdana;
}
.content-collapse {
  cursor: pointer;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  border-bottom: solid 1px #f2f2f2;
  border-radius: 0 0 5px 5px;
  padding: 15px;
  font-family: verdana;
  font-size: 14px;
}

.content-accordion {
  color: navy;
  font-family: verdana;
  font-size: 14px;
}

.no-bullets-nav {
  width: 100%;
}

ul.no-bullets-nav {
  background-color: #0ea1e6;
  /* background-image: url("./clientapp/img/elephantherd.jpg"); */
  min-height: 6vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  list-style-type: none; /* Remove bullets */
  padding: 5px; /* Remove padding */
  margin: 0; /* Remove margins */
  text-align: center;
}

ul.no-bullets-nav li {
  display: inline;
  margin-inline: 10px;
  padding: 10px;
}

ul.no-bullets-nav li a {
  color: navy;
  font-weight: bold;
  text-decoration: none;
}

/* visited link */
ul.no-bullets-nav li a:visited {
  color: #66c0fc;
}

/* mouse over link */
ul.no-bullets-nav li a:hover {
  color: #0ca131;
}

/* selected link */
ul.no-bullets-nav li a:active {
  color: #f7f707;
}

.no-bullets-nav-palin {
  width: 100%;
}

ul.no-bullets-nav-palin {
  background-color: #0ea1e6;
  min-height: 6vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  list-style-type: none; /* Remove bullets */
  padding: 5px; /* Remove padding */
  margin: 0; /* Remove margins */
  text-align: center;
}

ul.no-bullets-nav-palin li {
  display: inline;
  margin-inline: 6px;
  padding: 6px;
  font-weight: bold;
  font-size: 18px;  
}

ul.no-bullets-nav-palin li a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

/* visited link */
ul.no-bullets-nav-palin li a:visited {
  color: yellow;
}

/* mouse over link */
ul.no-bullets-nav-palin li a:hover {
  color: red;
}

/* selected link */
ul.no-bullets-nav-palin li a:active {
  color: yellow;
}

.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  margin: 0 -16px;
}

.col-25 {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
}

.col-50 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
}

.col-75 {
  -ms-flex: 75%; /* IE10 */
  flex: 75%;
}

.col-25,
.col-50,
.col-75 {
  padding: 0 16px;
}

input[type="text"] {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input[type="email"] {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input[type="password"] {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input[type="submit"] {
  background-color: #4caf50;
  width: 50%;
  margin-bottom: 20px;
  height: 50px;
  border-radius: 3px;
}

input[type="submit"]:hover {
  background-color: #0099ff;
}

textarea {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

label {
  margin-bottom: 10px;
  display: block;
}

.icon-container {
  margin-bottom: 20px;
  padding: 7px 0;
  font-size: 24px;
}

.btn {
  background-color: #4caf50;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
}

.btn:hover {
  background-color: #45a049;
}

span.price {
  float: right;
  color: grey;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (and change the direction - make the "cart" column go on top) */
@media (max-width: 800px) {
  .row {
    flex-direction: column-reverse;
  }
  .col-25 {
    margin-bottom: 20px;
  }

  .slideshow_deploy {   
    display:none
     }
 
     .slideshow_deploy_web {
       flex-direction: column;
       align-items: center;
       justify-content: center;
       text-align: center;
       max-width:450px;
       max-height:600px;
       width: 280px;
       height: 600px;
       margin: auto; 
       background: url("./clientapp/img/mobilemode1.png");    
 
     }
 
     .akwaba-content_deploy{ 
       width:100%;      
       }
    
    
  p {
    text-align: center;
    font-weight: 500;
  }

  select,
  input.error {
    border-color: red;
  }

  .div-separator {
    top: 40px;
    bottom: 40px;
  }

  .toggleelements{
    color:green;
    font-weight: bold;
    font-size:20px;
  }

  #nprogress .bar {
    background: red !important;
 }

#nprogress .peg {
    box-shadow: 0 0 10px red, 0 0 5px red !important;
 }

#nprogress .spinner-icon {
    border-top-color: red !important;
    border-left-color: red !important;
 }
}
